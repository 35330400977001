import { combineReducers } from 'redux';
import uiReducer from './ui/reducers/ui';
import authReducer from './auth/reducers/auth';
import campaignReducer from './campaign/reducers/campaign'
import dashboardReducer from './dashboard/reducers/dashboard'

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  campaign: campaignReducer,
  dashboard: dashboardReducer
});

export default rootReducer;
