export const GET_DASHBOARD_REQUEST = 'get_dashboard_request';
export const GET_DASHBOARD_SUCCESS = 'get_dashboard_success';
export const GET_DASHBOARD_FAILURE = 'get_dashboard_failure';

export const GET_CAMPAIGN_REQUEST = 'get_campaign_request';
export const GET_CAMPAIGN_SUCCESS = 'get_campaign_success';
export const GET_CAMPAIGN_FAILURE = 'get_campaign_failure';
export const SET_CAMPAIGN = 'set_campaign';

export const GET_EFLAG_DATA_REQUEST = 'get_eflag_data_request';
export const GET_EFLAG_DATA_SUCCESS = 'get_eflag_data_success';
export const GET_EFLAG_DATA_FAILURE = 'get_eflag_data_failure';

export const EFLAG_AVG_TAFFIC_SUCCESS = 'EFLAG_AVG_TAFFIC_SUCCESS'

export const GET_DEMOGRAPIC_DATA_REQUEST = 'get_demograpic_data_request';
export const GET_DEMOGRAPIC_DATA_SUCCESS = 'get_demograpic_data_success';
export const GET_DEMOGRAPIC_DATA_FAILURE = 'get_demograpic_data_failure';

export const SELECT_ASSET = 'select_asset'
export const RESET_ASSET = 'reset_asset'
export const SELECT_CLIENT = 'SELECT_CLIENT'
export const SELECTED_CITY = 'SELECTED_CITY'

export const SELECT_CITY_ASSETS = 'SELECT_CITY_ASSETS'
export const RESET_CITY_ASSETS = 'RESET_CITY_ASSETS'