import {
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isError: false,
  errorMessage: null,
  assets: [],
};

const Campaign = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        assets: action.payload
      };
    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}

export default Campaign