/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { removeToast } from '../../../store/ui/actions/toast';
import Toast from '../components/Toast';

class ToastsContainer extends React.Component {
  handleClose = id => {
  };

  render() {
    const { toasts } = this.props;
    return (
      <Fragment>
        {toasts.length > 0 &&
          <Fragment>
            {toasts.map((toast, index) => (
              <Toast id={index} toast={toast} key={toast.id} handleCloseToast={this.handleClose} />
            ))}
          </Fragment>
        }
      </Fragment>
    );
  }
}

ToastsContainer.propTypes = {
  toasts: PropTypes.array.isRequired,
  removeToast: PropTypes.func.isRequired
};
function mapStateToProps(state) {
  return {
    toasts: state.ui.all
  };
}
export default connect(mapStateToProps, { removeToast })(ToastsContainer);
