import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  SET_CAMPAIGN,
  GET_EFLAG_DATA_REQUEST,
  GET_EFLAG_DATA_SUCCESS,
  GET_EFLAG_DATA_FAILURE,
  GET_DEMOGRAPIC_DATA_REQUEST,
  GET_DEMOGRAPIC_DATA_SUCCESS,
  GET_DEMOGRAPIC_DATA_FAILURE,
  SELECT_ASSET,
  RESET_ASSET,
  SELECT_CITY_ASSETS,
  RESET_CITY_ASSETS,
  SELECTED_CITY,
  EFLAG_AVG_TAFFIC_SUCCESS
} from '../constants';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingEFlag: false,
  isFetchingWestD: false,
  isError: false,
  errorMessage: null,
  clients: [],
  locations: [],
  campaigns: [],
  contentUrls: [],
  selectedCampaign: null,
  campaignAssets: [],
  cityCampaignAssets: [],
  city: [],
  avgSignalWaitingTime: [],
  avgVehicleCount: [],
  graphData: [],
  realtimeVehicleExposure: [],
  videoStreams: [],
  assetWiseDemoGData: [],
  totalDemograpicData: [],
  containAnalysis: [],
  timeSlotAnalysis: [],
  contentSlotAnalytics: [],
  contentSlotAnalyticsNew: {},
  assetWiseAvgSpeed: [],
  impressionData: [],
  SlotPlayedCount: [],
  totalAvgSpeed: null,
  selectedAsset: null,
  selectedCity: null
};

const Dashboard = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaigns: action.payload.campaigns,
        city: action.payload.cities,
        locations: action.payload.locations,
        selectedCampaign: action.payload.selectedCampaign,
        campaignAssets: action.payload.media_assets,
        cityCampaignAssets: action.payload.media_assets,
        contentUrls: action.payload.content_urls,
        containAnalysis: action.payload.content_analytics,
        timeSlotAnalysis: action.payload.time_slot_analytics,
        contentSlotAnalytics: action.payload.content_slot_analytics,
        impressionData: action.payload.impression_analitics,
        SlotPlayedCount: action.payload.slot_wise_content,
        clients: action.payload?.client_list ?? state.clients
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload
      };

    case GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaignAssets: action.payload.assets,
        cityCampaignAssets: action.payload.assets,
        contentUrls: action.payload.content_urls,
        containAnalysis: action.payload.content_analytics,
        timeSlotAnalysis: action.payload.time_slot_analytics,
        contentSlotAnalytics: action.payload.content_slot_analytics,
        impressionData: action.payload.impression_analitics,
        SlotPlayedCount: action.payload.slot_wise_content,
        city: action.payload.city,
      };
    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload
      };
    case SET_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload
      };
    case GET_EFLAG_DATA_REQUEST:
      return {
        ...state,
        isFetchingEFlag: true
      };
    case GET_EFLAG_DATA_SUCCESS:
      return {
        ...state,
        isFetchingEFlag: false,
        avgSignalWaitingTime: action.payload.average_signal_waiting_time,
        avgVehicleCount: action.payload.average_vehicle_count,
        graphData: action.payload.graph_data,
        realtimeVehicleExposure: action.payload.realtime_vehicle_exposure,
        videoStreams: action.payload.video_streams
      };
    case EFLAG_AVG_TAFFIC_SUCCESS:
      return {
        ...state,
        contentSlotAnalyticsNew: action.payload
      };
    case GET_EFLAG_DATA_FAILURE:
      return {
        ...state,
        isError: true,
        isFetchingEFlag: false,
        errorMessage: action.payload
      };
    case GET_DEMOGRAPIC_DATA_REQUEST:
      return {
        ...state,
        isFetchingWestD: true
      };
    case GET_DEMOGRAPIC_DATA_SUCCESS:
      return {
        ...state,
        isFetchingWestD: false,
        assetWiseDemoGData: action.payload.asset_wise_summary,
        totalDemograpicData: action.payload.total_summary,
        assetWiseAvgSpeed: action.payload.average_speed,
        totalAvgSpeed: action.payload.overall_avg_speed
      };
    case GET_DEMOGRAPIC_DATA_FAILURE:
      return {
        ...state,
        isError: true,
        isFetchingWestD: false,
        errorMessage: action.payload
      };
    case SELECT_ASSET:
      return {
        ...state,
        selectedAsset: action.payload
      };
    case RESET_ASSET:
      return {
        ...state,
        selectedAsset: null
      };
    case SELECT_CITY_ASSETS:
      return {
        ...state,
        cityCampaignAssets: action.payload
      };
    case SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload
      };
    case RESET_CITY_ASSETS:
      return {
        ...state,
        cityCampaignAssets: state.campaignAssets
      };
    default:
      return state;
  }
}

export default Dashboard