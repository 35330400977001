import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILURE,
} from '../constants';

const INITIAL_STATE = {
  authenticated: false,
  isFetching: false,
  isError: false,
  errorMessage: null,
  signinError: null,
  user: {}
};

const Auth = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        user: action.payload
      };
    case UNAUTH_USER:
      return {};
    case AUTH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        errorMessage: null,
        signinError: null
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        isFetching: false,
        signinError: null,
        user: action.payload
      };
    case AUTH_USER_FAILURE:
      return {
        ...state,
        authenticated: false,
        isFetching: false,
        isError: true,
        signinError: action.payload,
        user: {}
      };
    default:
      return state;
  }
}

export default Auth
