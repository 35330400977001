import React, { lazy } from 'react';
import { useSelector } from 'react-redux'
import withRoot from './routes/shared/hoc/withRoot';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import withSuspense from './routes/shared/hoc/withSuspense';
import Toast from './routes/shared/containers/ToastsContainer';
import './App.css';

// Lazy loading components
const Signin = lazy(() => import(/* webpackChunkName: "Signin" */ './routes/signin'));
const Signout = lazy(() => import(/* webpackChunkName: "Signin" */ './routes/signout'));
const Accounts = lazy(() => import(/* webpackChunkName: "Dashboard" */ './routes/accounts'));
const PublicPage = lazy(() => import(/* webpackChunkName: "Landing page" */ './routes/publicpages'));

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/signin' }} />)}
  />
);
PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

// Redirect public routes
const PublicRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => (!authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/accounts/dashboard' }} />)}
  />
);
PublicRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired
};

function App() {
  const auth = useSelector(state => state.auth)
  const { authenticated } = auth;
  return (
    <div>
      <Toast />
      <Router>
        <PublicRoute exact path='/' component={withSuspense(Signin)} authenticated={authenticated} />
        <PublicRoute path="/signin" component={withSuspense(Signin)} authenticated={authenticated} />
        <PrivateRoute path='/accounts' component={withSuspense(Accounts)} authenticated={authenticated} />
        <Route path="/signout" component={withSuspense(Signout)} />
      </Router>
    </div>
  );
}

export default withRoot(App);
